<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                type="text"
                placeholder="Creditor Name"
                v-model="value.creditorName"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                type="text"
                placeholder="Invoice No"
                v-model="value.invoiceNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                type="text"
                placeholder="Purchase Order No."
                v-model="value.poNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                type="text"
                placeholder="Quote No"
                v-model="value.quoteNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>

          <div class="field">
            <v-date-picker v-if="value.filterType != 2 "
              v-model="selectedFromDate"
              :masks="formats"
              popover-visibility="focus"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '00:00:00' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Date From..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <v-date-picker v-if="value.filterType != 2"
              v-model="selectedToDate"
              :masks="formats"
              popover-visibility="focus"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '23:59:59' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Date To..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>

          <div class="field">
            <v-date-picker v-if="value.filterType == 2"
              v-model="selectedFromDate"
              :masks="formats"
              popover-visibility="false"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '00:00:00' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Date From..."
                      disabled="false"
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template></v-date-picker>
          </div>
          <div class="field"
            v-if="value.filterType == 2">
            <v-date-picker v-model="selectedToDate"
              :masks="formats"
              popover-visibility="false"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '23:59:59' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Date To..."
                      disabled="false"
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>

          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="value.exported">
                  <option value="B">-- Exported Inv. Status --</option>
                  <option value="E">Exported</option>
                  <option value="U">Not Exported</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field has-addons">
            <div class="control">
              <input class="is-checkradio"
                checked
                id="nonzerobalance"
                type="radio"
                name="FilterType"
                value="1"
                v-model="value.filterType">
              <label for="nonzerobalance">Invoices with Non-Zero Balance Only</label>
            </div>
          </div>
          <div class="field has-addons">
            <div class="control">
              <input class="is-checkradio"
                id="lastsixmonth"
                type="radio"
                name="FilterType"
                value="2"
                v-model="value.filterType">
              <label for="lastsixmonth">All Invoices within Last 6 Months</label>
            </div>
          </div>
          <div class="field has-addons">
            <div class="control">
              <input class="is-checkradio"
                id="allinvoice"
                type="radio"
                name="FilterType"
                value="0"
                v-model="value.filterType">
              <label for="allinvoice">All Invoices</label>
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>
<script>
import AppSideMenu from '@/components/AppSideMenu'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'CreditorListSideMenu',
  components: {
    AppSideMenu
  },
  props: {
    filter: null,
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedActive: 1,
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function(newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    },
    selectedFromDate: function(newVal, oldVal) {
      if (newVal) {
        this.innerValue.startDate = this.selectedFromDate.toISOString()
      } else {
        this.innerValue.startDate = ''
      }
    },
    selectedToDate: function(newVal, oldVal) {
      if (newVal) {
        this.innerValue.endDate = this.selectedToDate.toISOString()
      } else {
        this.innerValue.endDate = ''
      }
    },
    'value.filterType': function(newVal, oldVal) {
      if (newVal === '2') {
        this.selectedFromDate = null
        this.selectedToDate = null
      }
    }
  },
  created() {
    if (this.innerValue.startDate) {
      this.selectedFromDate = new Date(this.innerValue.startDate)
    }
    if (this.innerValue.endDate) {
      this.selectedToDate = new Date(this.innerValue.endDate)
    }
  },
  mounted() {},
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.$emit('reset')
      this.selectedFromDate = null
      this.selectedToDate = null
    }
  }
}
</script>
